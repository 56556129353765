import React, { useState, useMemo, Suspense, lazy } from "react";
import { ReactSVG } from "react-svg";

import Translator from "components/Translator";
import MarketingTestimonials from "./MarketingTestimonials";
import usFlag from "assets/images/home/us-flag.svg";
import sildenafilBottle from "assets/images/home/sildenafil_bottle_new_with_shadow.png";
import tadalafilBottle from "assets/images/home/tadalafil_bottle_new_with_shadow.png";
import checkMarkBullet from "assets/images/home/check-mark-bullet.svg";
import ratingStars from "assets/images/home/rating-stars.svg";

import "./styles.scss";
import { isEmpty } from "lodash";

// Lazy load PatientTestimonialsModal to reduce initial load time
const PatientTestimonialsModal = lazy(() =>
  import("../Home/Modals/PatientTestimonialsModal")
);

type ProductDetailProps = {
  latestCart?: any;
};

const BottleSection = ({ latestCart }: ProductDetailProps) => {
  const [showTestimonialModal, setShowTestimonialModal] =
    useState<boolean>(false);

  // Ensure selectedProduct is defined to prevent undefined class in mobile view
  const selectedProduct = latestCart?.product_variant?.product_name?.toLowerCase() || 'default';

  // Memoize bullet points for rendering efficiency
  const bulletPoints = useMemo(
    () => [
      "Expert Pharmacist Guidance",
      "No Monthly Subscriptions",
      "Free Online Doctor Visit",
      "FDA Approved",
      "Low Price Guarantee",
    ],
    []
  );

  // Determine the bottle image based on the selected product
  const bottleImage = useMemo(
    () => selectedProduct === "tadalafil" ? tadalafilBottle : sildenafilBottle,
    [selectedProduct]
  );

  // Function to format the product name
  const formatProductName = (cart: any) => {
    if (isEmpty(cart)) return "";

    const { product_variant } = cart;

    if (
      product_variant.product_name === "Sildenafil" &&
      product_variant.dosage_value === 20
    ) {
      return `${product_variant.dosage} ${product_variant.product_name}`;
    }

    return `${product_variant.dosage} ${cart.product_name_with_brand}`;
  };

  return (
    <div className="bottle-container">
      {/* Desktop view */}
      <div className="main-content non-tablet d-none d-lg-block">
        <div className="us-official-source">
          <img src={usFlag} alt="US Flag" loading="lazy" />
          <span className="us-text">US Lab Tested & Official Source</span>
        </div>
        <h2 className="heading mt-2">{formatProductName(latestCart)}</h2>
        <div className="d-flex">
          <img
            src={bottleImage}
            alt={`${selectedProduct} Bottle`}
            className="mx-auto mt-3 medicine-bottle"
            loading="lazy"
          />
        </div>
        <div className="row bottom-section platform-features">
          {bulletPoints.map((bp, idx) => (
            <div className="col-6 feature" key={idx}>
              <img
                src={checkMarkBullet}
                alt="Check Mark Bullet"
                loading="lazy"
              />
              <span>{bp}</span>
            </div>
          ))}
        </div>
        <div className="testimonials">
          <MarketingTestimonials />
        </div>
      </div>

      {/* Mobile view */}
      <div
        className={`main-content tablet d-block d-lg-none ${selectedProduct ?? ''}`}
        id="non-desktop"
      >
        <div className="row">
          <div className="col-11">
            <div className="us-official-source">
              <img src={usFlag} alt="US Flag" loading="lazy" />
              <span className="us-text">US Lab Tested & Official Source</span>
            </div>
            <h2 className="heading mt-2">{formatProductName(latestCart)}</h2>
            <button
              className="sublink px-0 testimonial-link testimonail-modal-btn mt-0"
              type="button"
              onClick={() => setShowTestimonialModal(!showTestimonialModal)}
            >
              <Translator path="homepage.hero.testimonialLink" />
            </button>
            <ReactSVG
              src={ratingStars}
              wrapper="span"
              role="img"
            />
             {showTestimonialModal && (
              <PatientTestimonialsModal
                show={showTestimonialModal}
                setShow={setShowTestimonialModal}
              />
            )}

            <div className="platform-features flex-column">
              {bulletPoints.map((bp, idx) => (
                <div className="feature" key={idx}>
                  <img
                    src={checkMarkBullet}
                    alt="Check Mark Bullet"
                    loading="lazy"
                  />
                  <span>{bp}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="testimonials">
          <MarketingTestimonials />
        </div>
      </div>
    </div>
  );
};

export default BottleSection;
