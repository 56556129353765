import { useState } from "react";
import { isEmpty } from "lodash";
import AuthContextProvider from "../Contexts/AuthContext";
import AllRoutes from "./allRoutes";
import Navbar from "../components/Navbar";
import CartContextProvider from "../Contexts/CartContext";
import OrderContextProvider from "../Contexts/OrderContext";
import SiteAuth from "SiteAuth";

const MainRouter = () => {
  const [siteToken, setSiteToken] = useState(
    sessionStorage.getItem("siteToken")
  );

  const validateToken = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === "uat") {
    //   if (
    //     !isEmpty(siteToken) &&
    //     (process.env.REACT_APP_SITE_TOKEN === siteToken ||
    //       process.env.REACT_APP_SITE_GUEST_TOKEN === siteToken)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
      return true;
    // }
  };

  const handleSiteLogIn = (key: string) => {
    if (
      key === process.env.REACT_APP_SITE_TOKEN ||
      key === process.env.REACT_APP_SITE_GUEST_TOKEN
    ) {
      sessionStorage.setItem("siteToken", key);
      setSiteToken(key);
    }
  };

  return (
    <>
      {validateToken() ? (
        <AuthContextProvider>
          <CartContextProvider>
            <OrderContextProvider>
              <Navbar />
              <AllRoutes />
            </OrderContextProvider>
          </CartContextProvider>
        </AuthContextProvider>
      ) : (
        <SiteAuth handleSiteLogIn={handleSiteLogIn} />
      )}
    </>
  );
};

export default MainRouter;
