import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import { CartContext, CartType } from "Contexts/CartContext";
import { AuthContext } from "Contexts/AuthContext";
import { useSearchParams } from "react-router-dom";
import { useRollbar } from "@rollbar/react";
import { isEmpty } from "lodash";
import { CreateSlugCartRequest } from "apis";
import useLatestCart from "hooks/useLatestCart";
import { FbApiDataExtraction } from "helpers/FbApiDataExtraction";

// Lazy load components to improve performance
const BottleSection = lazy(() => import("./BottleSection"));
const ProductDetail = lazy(() => import("containers/Checkout/MarketingProductDetail"));
const LabTested = lazy(() => import("containers/Home/LabTested"));
const Process = lazy(() => import("containers/Home/Process"));
const AboutEd = lazy(() => import("containers/Home/AboutEd"));
const WhatIsIncluded = lazy(() => import("containers/Home/WhatIsIncluded"));
const RealResults = lazy(() => import("containers/Home/RealResults"));
const ViagraVsSildenafil = lazy(() => import("containers/Home/ViagraVsSildenafil"));
const HappyPatients = lazy(() => import("containers/Home/HappyPatients"));
const Faq = lazy(() => import("containers/Home/Faq"));
const BottomCta = lazy(() => import("containers/Home/BottomCta"));
const Footer = lazy(() => import("components/Footer"));
const ChatReminderModal = lazy(() => import("containers/Home/Modals/ChatReminderModal"));
const ResumeRefillInProgressModal = lazy(() => import("components/Modals/ResumeRefllModal"));
const DuplicateCartModal = lazy(() => import("components/Modals/DuplicateCartModal"));
const RefillAvailableModal = lazy(() => import("components/Modals/RefillAvailableModal"));
const ResumeVisitModal = lazy(() => import("components/Modals/ResumeVisitModal"));
const UnderReviewVisitModal = lazy(() => import("components/Modals/UnderReviewVisitModal"));
const RetakeModal = lazy(() => import("containers/Home/Modals/RetakeModal"));

const MarketingLandingPage = () => {
  const { setLatestCart, setCartProductVariant } = useContext(CartContext);
  const { ReloadCart } = useContext(CartContext);
  const {
    showChatReminderModal,
    showRetakeModal,
    setShowRetakeModal,
    setShowChatReminderModal,
    reloadUser,
  } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const [slug, setSlug] = useState<string>("sildenafil-citrate-20-mg");
  const [utmData, setUtmData] = useState<any>();
  const [guestCart, setGuestCart] = useState<CartType>();
  const latestCart = useLatestCart();
  const rollbar = useRollbar();

  // Initialize slug and utmData
  useEffect(() => {
    localStorage.setItem("isMarketing", "marketing");

    const pathSlug = window.location.pathname.split("/")[2];
    setSlug(pathSlug ?? "sildenafil-citrate-20-mg");

    const utmInfo = FbApiDataExtraction(window.location.href);
    setUtmData(utmInfo);
  }, []);

  // Handle cart creation
  useEffect(() => {
    if (!isEmpty(slug) && !latestCart?.marketing) {
      const CartToken = localStorage.getItem("CartToken") ?? "";

      CreateSlugCartRequest(
        {
          slug,
          qty: searchParams.get("qty"),
          cart_token: CartToken,
        },
        utmData
      )
        .then((res) => {
          const cart = res.data.cart;

          setLatestCart(cart);
          setGuestCart(cart);
          setCartProductVariant(res.data.product_variant);

          localStorage.setItem("CartToken", cart.token);
          localStorage.removeItem("intro-answers");
        })
        .catch((err) => {
          rollbar.error(err);
        });
    }
  }, [slug]);

  // Reload cart and user data
  useEffect(() => {
    ReloadCart();
    reloadUser();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="main">
        <div className="row flex-column flex-md-row">
          <div className="col-lg-6 col-12" id="bottle-section">
            <BottleSection latestCart={latestCart} />
          </div>
          <div className="col-lg-6 col-12" id="product-detail-section">
            <ProductDetail cart={latestCart} marketingPage={true} />
          </div>
        </div>
        <LabTested slug={true} defaultProductVariant={undefined} />
        <Process slug={true} defaultProductVariant={undefined} />
        <AboutEd />
        <WhatIsIncluded />
        <RealResults />
        <ViagraVsSildenafil />
        <RetakeModal setShow={setShowRetakeModal} show={showRetakeModal} />
        <ChatReminderModal
          setChatReminderModal={setShowChatReminderModal}
          show={showChatReminderModal}
        />
        <ResumeRefillInProgressModal />
        <ResumeVisitModal slug={true} defaultProductVariant={undefined} />
        <RefillAvailableModal slug={true} defaultProductVariant={undefined} />
        <UnderReviewVisitModal />
        <DuplicateCartModal />
        <HappyPatients slug={true} defaultProductVariant={undefined} />
        <Faq />
        <BottomCta slug={true} defaultProductVariant={undefined} />
        <Footer />
      </div>
    </Suspense>
  );
};

export default MarketingLandingPage;
